import * as tslib_1 from "tslib";
import * as React from 'react';
import { View, } from 'react-native';
import styles from './styles';
var Item = /** @class */ (function (_super) {
    tslib_1.__extends(Item, _super);
    function Item() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Item.prototype.render = function () {
        var _a = this.props, oneColumnSize = _a.oneColumnSize, borderWidth = _a.borderWidth, index = _a.index, style = _a.style, tenthItemStyle = _a.tenthItemStyle;
        return (<View style={[
            styles.subBlock,
            { width: oneColumnSize, borderRightWidth: borderWidth },
            (index + 1) % 10 === 0 ? { borderRightWidth: borderWidth + 2, height: 70 } : null,
            style,
            (index + 1) % 10 === 0 ? tenthItemStyle : null,
        ]}/>);
    };
    Item.defaultProps = {
        style: null,
        tenthItemStyle: null,
    };
    return Item;
}(React.PureComponent));
export default Item;
